<template>
    <div stripe border v-loading="loading"
         element-loading-text = "加载中,请稍后..."
         element-loading-background="rgba(0, 0, 0, 0.7)">
        <mt>
            <el-button @click="addUser"
                       v-if="isAdmin||isHr">添加客服
            </el-button>

            <el-button
                    @click="setHR" v-if="isAdmin||isHr">设置人事
            </el-button>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span class="title">客服信息</span>
            </div>

            <el-table  height="700px" :data="users" class="funBtn">
                <el-table-column label="NO." type="index" width="70px"></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="手机号" prop="mobile"></el-table-column>
                <el-table-column label="类型" prop="type">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==2" style="color:green">人事</span>
                        <span v-if="scope.row.type==3" style="color:blue">管理员</span>
                    </template>
                </el-table-column>
                <el-table-column label="用户名" prop="username"></el-table-column>
                <el-table-column label="注册时间" prop="createTime"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="onEdit(scope.row)" v-if="isAdmin||isHr">编辑</el-button>
                        <el-button @click="onRepass(scope.row)" v-if="isAdmin||(isHr&&scope.row.type!=3)">重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="onQuery"
                               @current-change="onQuery"
                               :current-page.sync="params.page"
                               :page-sizes="[50, 100]"
                               :page-size.sync="params.size"
                               layout="sizes, prev, pager, next,total"
                               :total="params.total">
                </el-pagination>
            </div>
            <el-dialog :visible.sync="showHrDialog" width="500px" title="设置人事">
                <el-form label-width="120px" ref="repass">

                    <el-form-item label="选择人事">
                        <el-input v-model="hrMobile" style="width: 260px"></el-input>
                    </el-form-item>
                    <div style="color:red;margin-bottom: 20px">使用该号码注册的员工账号,将被设置为人事</div>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onHROk">确定</el-button>
                    <el-button @click="showHrDialog=false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showRepass" width="500px" title="重置密码">
                <el-form label-width="120px" class="funBtn" :model="repass" ref="repass">
                    <el-form-item label="新密码" prop="pass">
                        <el-input v-model="repass.pass" clearable="true" show-password style="width: 260px"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="newPass">
                        <el-input v-model="repass.newPass" clearable="true" show-password style="width: 260px"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onRepassOk">确定</el-button>
                    <el-button @click="showRepass=false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showDialog" width="500px" title="添加/编辑客服">
                <el-form label-width="120px" :model="newUser" :rules="rules" ref="newUser">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="newUser.name" style="width: 260px"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="newUser.mobile" style="width: 260px"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名" v-if="!newUser['id']" prop="username">
                        <el-input v-model="newUser.username" style="width: 260px"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" v-if="!newUser['id']" prop="password">
                        <el-input v-model="newUser.password" show-password style="width: 260px"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onCreateOk">确定</el-button>
                    <el-button @click="onCreateCancel">取消</el-button>
                </div>
            </el-dialog>
        </el-card>
    </div>

</template>

<script>
    import mt from "@/components/MainTop.vue";

    export default {
        components: {mt},
        name: "users",
        data() {
            return {
                loading:false,
                showDialog: false,
                showHrDialog: false,
                showRepass: false,
                hrMobile: "",
                params: {
                    page: 1,
                    size: 50,
                    total: 0
                },
                newUser: {
                    name: "",
                    mobile: "",
                    username: "",
                    password: "",
                    id: ""
                },
                repass: {
                    id: "",
                    pass: "",
                    newPass: "",
                },
                rulesCreate: {
                    name: [{required: true, message: '请输入客服姓名', trigger: 'blur'}],
                    mobile: [{required: true, message: '请输入客服手机号并确认格式', trigger: 'blur',pattern:/^1\d{10}$/},],
                    username: [{required: true, message: '请输入用户名', trigger: 'blur'},],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'},]
                },
                rulesEdit: {
                    name: [{required: true, message: '请输入客服姓名', trigger: 'blur'}],
                    mobile: [{required: true, message: '请输入客服手机号并确认格式', trigger: 'blur', pattern:/^1\d{10}$/},]
                },
                users: [],
            }
        },
        computed: {
            rules() {
                if (this.newUser.id) {
                    return this.rulesEdit;
                } else {
                    return this.rulesCreate;
                }
            }
        },
        mounted() {
            this.onQuery();
        },
        methods: {
            onQuery() {
                let _this = this;
                this.loading = true
                this.$http.get("/api/user/find", {params: this.params})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            _this.users = rsp.data.data || [];
                            this.params.total = rsp.data.count;
                            this.loading = false
                        }
                    })
            },
            addUser() {
                this.clearData();
                this.showDialog = true;
            },
            setHR() {
                this.clearData();
                this.showHrDialog = true;
            },
            onHROk() {
                if ((!(this.hrMobile)) || (!this.hrMobile.trim())) {
                    return;
                }
                this.$http.post("/api/user/hr/" + this.hrMobile)
                    .then((rsp) => {
                        if (rsp.data.status == 200) {
                            this.showHrDialog = false;
                            this.$alert('人事设置成功', '提示', {
                                confirmButtonText: '确定',
                                type:"success",
                                callback: action => {
                                    if(this.isHr){
                                        this.$http.get("/api/logout").then(rsp=>{
                                            location.href="/";
                                        })
                                    }else{
                                        this.onQuery();
                                    }
                                }
                            });


                        }
                    }).catch((rsp) => {
                    this.$message.error(rsp.response.data.message);
                })
            },
            onCreateOk() {
                this.$refs['newUser'].validate((valid) => {
                        if (!valid) {
                            return
                        }
                        if (this.newUser.id) {//编辑
                            let param = {
                                id: this.newUser.id,
                                name: this.newUser.name,
                                mobile: this.newUser.mobile
                            }
                            this.$http.patch("/api/user/edit/" + param.id, param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("编辑成功");
                                        this.showDialog = false;
                                        this.onQuery();
                                        this.clearData();
                                    }
                                }).catch((rsp) => {
                                this.$message.error(rsp.response.data.message);
                                this.onQuery();
                            })
                        } else {//新建
                            let param = JSON.parse(JSON.stringify(this.newUser));
                            this.$http.post("/api/user/create", param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("创建成功");
                                        this.showDialog = false;
                                        this.onQuery();
                                        this.clearData();
                                    }
                                })
                                .catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);
                                    this.onQuery();
                                })
                        }
                    }
                );
            },
            onCreateCancel() {
                this.showDialog = false;
            },
            onEdit(row) {
                this.clearData();
                this.newUser = JSON.parse(JSON.stringify(row));
                this.showDialog = true;
            },
            clearData() {
                this.newUser = {
                    name: "",
                    mobile: "",
                    username: "",
                    password: "",
                    id: ""
                }
            },
            onRepass(row) {
                this.repass = {
                    id: row.id,
                    pass: "",
                    newPass: "",
                };
                this.showRepass = true;
            },
            onRepassOk() {
                if ((!(this.repass['pass'])) || (!this.repass.pass.trim())) {
                    return;
                }
                if (this.repass.pass != this.repass.newPass) {
                    this.$message.error("两次输入密码不一致")
                    return;
                }
                this.$http.patch("/api/user/editPass/" + this.repass.id, null, {params: {newPass: this.repass.newPass}})
                    .then((rsp) => {
                        if (rsp.data.status == 200) {
                            this.$message.success("密码重置成功");
                            this.showRepass = false;
                        }
                    }).catch((rsp) => {
                    this.$message.error(rsp.response.data.message);
                })
            },
        }
    }
</script>

<style scoped>

</style>
